<template>
    <div class="home-page">
        <div class="banner vivify fadeIn">
            <el-carousel :height="bannerHeight+'px'">
                <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                    <img :src="item.banner" ref="img"/>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="contant1 mt-100 mt-0 pb-10">
            <div class="box1">
                <div class="title-text">
                    <div class="text1">{{ $t('menu.about') }}</div>
                    <div class="text2">ABOUT US</div>
                    <div class="line1"></div>
                </div>
                <div class="text3 mt-50 mt-0">
                    <span style="font-weight: 600">{{ $store.state.fromObj.title }}</span>
                    <span>{{ $store.state.fromObj.brief }}</span>
                </div>
            </div>
            <div class="box2 mt-10">
                <div class="video-container">
                    <video
                        ref="myVideo"
                        oncontextmenu="return false"
                        controlslist="nodownload"
                        class="video"
                        autoplay
                        controls
                        :poster="$store.state.fromObj.video_icon"
                    >
                        <source :src="$store.state.fromObj.video" type="video/mp4">
                    </video>

                    <!--              <button class="play-button"  @click="handlePlay"></button>-->
                </div>

                <!--        <img src="./img/image1.png"/>-->
            </div>
        </div>

        <div class="bc-f2">
            <div class="contant1 bc-f2 mt-100 mt-0 a-e pb-10">
                <div class="box1">
                    <div class="title-text ">
                        <div class="text1">{{ $t('menu.recommendation') }}</div>
                        <div class="text2">PRODUCTS</div>
                    </div>
                </div>
                <div class="box3" @click="articleClick('产品推荐')">
                    {{ $t('menu.more') }}
                    <img src="./img/right.png">
                </div>
            </div>
            <div class="content2 mt-50 mt-0 p-20 pb-0 ">
                <div class="box4 mb-30 mb-10" v-for="(item,index) in recommendData.slice(0,4)" :key="index">
                    <img class="image1" :src="item.image">
                    <div class="bc-95 p-30 p-20 mt--10 p-10-20">
                        <div class="flex-between mb-20 mb-10">
                            <div>{{ item.title }}</div>
                            <!--              <img src="./img/right1.png">-->
                        </div>
                        <div class="cl-d4">{{ item.title_en }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bc-ff">
            <div class="contant1 mt-50 mt-0 a-e pb-0">
                <div class="box1">
                    <div class="title-text">
                        <div class="text1">{{ $t('menu.news') }}</div>
                        <div class="text2">NEWS</div>
                    </div>
                </div>
                <div class="box3" @click="articleClick('新闻中心')">
                    {{ $t('menu.more') }}
                    <img src="./img/right.png">
                </div>
            </div>
            <div class="contant1 mt-50 mt-0 pb-0" v-for="(item,index) in articleData.slice(0,3)" :key="index" @click="NewsCenterClick(item,index)">
                <div class="box2 mt-20 d-n ds-b" v-if="index == 1">
                    <img class="image2" :src="item.image"/>
                </div>
                <div class="box1">
                    <div class="title-text m-top-20 paddin-0 p-0">
                        <div class="text4">{{ item.update_time }} <span>NEW</span></div>
                        <div class="text5">{{ item.title }}</div>
                    </div>
                    <div class="text6 m-top-20 mt-0">
                        <span>{{ item.desc }}</span>
                        <img src="./img/right2.png">
                    </div>
                </div>
                <div class="box2 mt-20 ds-n d-b" v-if="index == 1">
                    <img class="image2" :src="item.image"/>
                </div>
                <div class="box2 mt-20" v-if="index !==1">
                    <img class="image2" :src="item.image"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../assets/css/common.css'
import '@/views/scss/home-page.scss'

export default {
    name: "HomePage",
    data() {
        return {
            bannerList: [],
            articleData: [],
            recommendData: [],
            bannerHeight: '',

        }
    },
    created() {

    },
    mounted() {
        this.Interface()
        this.InterfaceList()

    },
    methods: {
        /**详情点击*/
        NewsCenterClick(item, index) {
            this.$router.push({
                name: 'NewsCenterDetails',
                query: item
            })
        },
        /**新闻中心更多点击*/
        articleClick(e) {
            if (e == '新闻中心') {
                this.$router.push({
                    name: 'NewsCenter'
                })
            } else if (e == "产品推荐") {
                this.$router.push({
                    name: 'Recommended'
                })
            }
            this.$store.commit('setcountText', e)
            window.scrollTo(0, 0);
        },
        async InterfaceList() {
            //新闻列表
            await this.$axios.post('pcapi/index/article', {}).then(res => {
                if (res.data.code == 1) {
                    this.articleData = res.data.data.list
                }
            })
            //产品列表
            await this.$axios.post('pcapi/index/recommend', {}).then(res => {
                if (res.data.code == 1) {
                    this.recommendData = res.data.data.list
                }
            })
        },
        async Interface() {
            //公司信息
            await this.$axios.post('pcapi/index/banner').then(res => {
                if (res.data.code == 1) {
                    this.bannerList = res.data.data
                    this.$nextTick(() => {
                        setTimeout(() => {
                            if (this.$refs.img[0]) {
                                this.bannerHeight = this.$refs.img[0].height
                                console.log('this.bannerHeight', this.bannerHeight, this.$refs.img, this.$refs.img[0])
                            }
                        }, 200)
                    })
                }
            })
        },
    }

}
</script>
<style>

</style>
